<template>
    <CContainer class="align-self-center">
        <CRow class="justify-content-center">
            <CCol :xs="{ span: 12, offset: 0 }" :md="{ span: 12, offset: 0 }" :lg="{ span: 8, offset: 0 }" :xl="{ span: 8, offset: 0 }" class="shadow p-3 mb-5 bg-light-subtle border-start-secondary rounded border ">
                <CCard>
                    <CCardHeader class="text-center h5">Ship To Information Management</CCardHeader>
                    <CCardBody>
                        <CContainer class="px-1">
                            <CRow class="pb-3">
                                <CCol lg="auto" md="auto" sm="auto" xl="auto">
                                    <DxDropDownBox
                                        ref="CustomersDropDownRef"
                                        v-model:value="selectedCustomer"
                                        v-model:opened="isCustomerGridBoxOpened"
                                        :input-attr="{ 'aria-label': 'Customer' }"
                                        :defer-rendering="false"
                                        :show-clear-button="true"
                                        :data-source="customers"
                                        :width="350"
                                        :display-expr="customerDropDownBoxDisplayExpr"
                                        value-expr="CustomerNo"
                                        placeholder="Select a Customer..."
                                        @value-changed="onCustomerValueChanged"
                                    >
                                        <DxDropDownOptions :width="750" height="auto" />

                                        <template #content="{ data }">
                                            <DxDataGrid
                                                :data-source="customers"
                                                :hover-state-enabled="true"
                                                :show-borders="true"
                                                :show-row-lines="true"
                                                :row-alternation-enabled="true"
                                                :repaint-changes-only="false"
                                                v-model:selected-row-keys="selectedCustomer"
                                                @selection-changed="onCustomerSelectionChanged"
                                                height="100%"
                                            >
                                                <DxSelection mode="single"/>
                                                <DxPaging :enabled="true" :page-size="10"/>
                                                <DxHeaderFilter :visible="true"/>
                                                <DxFilterRow :visible="true"/>
                                                <DxScrolling mode="virtual"/>
                                                <DxSearchPanel
                                                        :visible="true"
                                                        :highlight-case-sensitive="true"
                                                />

                                                <DxColumn
                                                    data-field="CustomerNo"
                                                    sort-index="0"
                                                    width="auto"
                                                    :header-filter="{width: 150, allowSearch: true, position: { my: 'center', at: 'center'} }"
                                                />
                                                <DxColumn
                                                    data-field="CustomerName"
                                                    :header-filter="{width: 'auto', allowSearch: true, position: { my: 'center', at: 'center'} }"
                                                />
                                                <DxColumn
                                                    data-field="City"
                                                    width="auto"
                                                    :header-filter="{width: 250, allowSearch: true, position: { my: 'center', at: 'center'} }"
                                                />
                                                <DxColumn
                                                    data-field="State"
                                                    width="auto"
                                                    :header-filter="{width: 175, allowSearch: true, position: { my: 'center', at: 'center'} }"
                                                />
                                                <DxColumn
                                                    data-field="ZipCode"
                                                    width="auto"
                                                    :header-filter="{width: 200, allowSearch: true, position: { my: 'center', at: 'center'} }"
                                                />
                                            </DxDataGrid>
                                        </template>
                                    </DxDropDownBox>
                                </CCol>
                            </CRow>

                            <CRow>
                                <CCol>
                                    <DxDataGrid
                                        ref="ShipToDataGridRef"
                                        :data-source="shiptos"
                                        :hover-state-enabled="true"
                                        :show-borders="true"
                                        :show-row-lines="true"
                                        :row-alternation-enabled="true"
                                        :repaint-changes-only="false"
                                        height="auto"
                                        width="auto"
                                        no-data-text="Select a Customer..."
                                        @saved="onSaved"
                                    >
                                        <DxSelection mode="single"/>
                                        <DxPaging :enabled="true" :page-size="10"/>
                                        <DxFilterRow :visible="true"/>
                                        <DxHeaderFilter :visible="true"/>
                                        <DxScrolling mode="virtual"/>

                                        <DxEditing
                                            :allow-updating="true"
                                            mode="popup">

                                            <DxPopup
                                                title="Information"
                                                shading-color="rgba(0,0,0,0.4)"
                                                :show-title="true"
                                                :width="600"
                                                height="auto"
                                            >
                                            </DxPopup>

                                            <DxForm
                                                label-location="top"
                                                :col-count="12"
                                            >
                                                <DxItem data-field="ShipToAddress1" :col-span="6" :editor-options="{ maxLength: 40 }"/>
                                                <DxItem data-field="UDF_ACTIVE" :col-span="6" editor-type="dxCheckBox" :label="{ location: 'left' }"/>

                                                <DxItem data-field="ShipToAddress2" :col-span="6" :editor-options="{ maxLength: 40 }"/>
                                                <DxItem data-field="UDF_LATITUDE" :col-span="3" />
                                                <DxItem data-field="UDF_LONGITUDE" :col-span="3" />

                                                <DxItem data-field="ShipToAddress3" :col-span="6" :editor-options="{ maxLength: 40 }"/>
                                                <DxEmptyItem :col-span="6"/>

                                                <DxItem data-field="ShipToCity" :col-span="6" :editor-options="{ maxLength: 30 }" />
                                                <DxEmptyItem :col-span="6"/>

                                                <DxItem data-field="ShipToState" :col-span="6" editor-type="dxSelectBox" :editor-options="{ dataSource: states(), displayExpr: function(state) { return state && `${state.abbreviation} - ${state.name}` }, valueExpr: 'abbreviation', keyExpr: 'abbreviation' }"/>
                                                <DxItem data-field="ShipToZipCode" :col-span="3"/>
                                                <DxEmptyItem :col-span="3"/>

                                                <DxItem data-field="ContactCode" :col-span="6" editor-type="dxSelectBox" :label="{ text: 'Contact' }" :editor-options="{ dataSource: customercontacts, displayExpr: 'ContactName', valueExpr: 'ContactCode', keyExpr: 'ContactCode' }"/>
                                                <DxEmptyItem :col-span="6"/>

                                                <DxItem data-field="WarehouseCode" :col-span="6" editor-type="dxSelectBox" :editor-options="{ dataSource: warehouses, displayExpr: function(warehouse) { return warehouse && `${warehouse.WarehouseCode} - ${warehouse.WarehouseName}` }, valueExpr: 'WarehouseCode', keyExpr: 'WarehouseCode' }"/>
                                                <DxItem data-field="SalespersonNo" :col-span="6" editor-type="dxSelectBox" :label="{ text: 'Sales Person' }" :editor-options="{ dataSource: salespersons, displayExpr: 'SalespersonName', valueExpr: 'SalespersonNo', keyExpr: 'SalespersonNo' }"/>

                                                <DxItem data-field="ShipVia" :col-span="6" :editor-options="{ maxLength: 15 }" />
                                                <DxItem data-field="SalesTaxSchedule" :col-span="6" :label="{ text: 'Tax Schedule' }" editor-type="dxSelectBox" :editor-options="{ dataSource: salestaxschedules, displayExpr: 'TaxScheduleDesc', valueExpr: 'TaxSchedule', keyExpr: 'TaxSchedule' }"/>

                                                <DxItem data-field="UDF_AVALARANOTES" :col-span="12" :label="{ text: 'Avalara Notes' }" editor-type="dxTextArea" :editor-options="{ height: 100, maxLength: 1000 }"/>
                                            </DxForm>
                                        </DxEditing>

                                        <DxColumn
                                            data-field="ShipToCode"
                                            caption="Code"
                                            sort-index="0"
                                            width="auto"
                                            :header-filter="{width: 175, allowSearch: true, position: { my: 'center', at: 'center'} }"
                                        />
                                        <DxColumn
                                            data-field="ShipToName"
                                            caption="Name"
                                            :header-filter="{width: 'auto', allowSearch: true, position: { my: 'center', at: 'center'} }"
                                        />
                                        <DxColumn
                                            data-field="ShipToCity"
                                            caption="City"
                                            :header-filter="{width: 'auto', allowSearch: true, position: { my: 'center', at: 'center'} }"
                                        />
                                        <DxColumn
                                            data-field="ShipToState"
                                            caption="State"
                                            width="auto"
                                            :header-filter="{width: 150, allowSearch: true, position: { my: 'center', at: 'center'} }"
                                        />
                                        <DxColumn
                                            data-field="ShipToZipCode"
                                            caption="Zip"
                                            width="auto"
                                            :header-filter="{width: 250, allowSearch: true, position: { my: 'center', at: 'center'} }"
                                        />
                                        <DxColumn
                                            data-field="WarehouseCode"
                                            caption="Warehouse"
                                            width="auto"
                                            :header-filter="{width: 175, allowSearch: true, position: { my: 'center', at: 'center'} }"
                                        />
                                        <DxColumn
                                                data-field="ShipToAddress1"
                                                caption="Address 1"
                                                :visible="false"
                                        />
                                        <DxColumn
                                                data-field="ShipToAddress2"
                                                caption="Address 2"
                                                :visible="false"
                                        />
                                        <DxColumn
                                                data-field="ShipToAddress3"
                                                caption="Address 3"
                                                :visible="false"
                                        />
                                        <DxColumn
                                                data-field="UDF_LATITUDE"
                                                caption="Latitude"
                                                :visible="false"
                                        />
                                        <DxColumn
                                                data-field="UDF_LONGITUDE"
                                                caption="Longitude"
                                                :visible="false"
                                        />
                                        <DxColumn
                                                data-field="UDF_ACTIVE"
                                                data-type="boolean"
                                                caption="Rig Active"
                                                :visible="false"
                                        />
                                        <DxColumn
                                                data-field="ContactCode"
                                                :visible="false"
                                        />
                                        <DxColumn
                                                data-field="SalespersonNo"
                                                :visible="false"
                                        />
                                        <DxColumn
                                                data-field="SalesTaxSchedule"
                                                :visible="false"
                                        />
                                        <DxColumn
                                                data-field="ShipVia"
                                                :visible="false"
                                        />
                                        <DxColumn
                                                data-field="UDF_AVALARANOTES"
                                                :visible="false"
                                        />
                                    </DxDataGrid>
                                </CCol>
                            </CRow>
                        </CContainer>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    </CContainer>
    <DxLoadPanel
            :visible="loading"
            :message="loadingText"
            :max-width="500"
            :min-width="250"
            indicator-src='./assets/rolling.svg'
            shading-color="rgba(0,0,0,0.4)"
    />
</template>

<script>
import { CCol, CContainer, CRow } from "@coreui/vue/dist/esm/components/grid";
import { CCard, CCardBody, CCardHeader } from "@coreui/vue/dist/esm/components/card";
import { DxDropDownBox, DxDropDownOptions } from 'devextreme-vue/drop-down-box';
import { DxDataGrid, DxColumn, DxSelection, DxPaging, DxFilterRow, DxScrolling, DxEditing, DxForm, DxLabel, DxPopup, DxHeaderFilter, DxSearchPanel } from 'devextreme-vue/data-grid';
import { DxItem, DxEmptyItem } from "devextreme-vue/form";
import { API } from "@/API";
import { DxLoadPanel } from 'devextreme-vue/load-panel';
import DxButton from "devextreme-vue/button";

import { DxToolbarItem } from 'devextreme-vue/popup';

import 'devextreme-vue/text-area';

import ShipTo from "@/models/ShipTo";
import Customer from "@/models/Customer";
import {states} from "@/constants";
import Warehouse from "@/models/Warehouse";
import CustomerContact from "@/models/CustomerContact";
import SalesTaxSchedule from "@/models/SalesTaxSchedule";
import SalesPerson from "@/models/SalesPerson";

export default {
    name: "ship-to-view",
    components: {
        CCard, CCardHeader, CCardBody, CContainer, CRow, CCol,
        DxDropDownBox, DxDropDownOptions,
        DxDataGrid, DxColumn, DxSelection, DxPaging, DxFilterRow, DxScrolling, DxEditing, DxForm, DxLabel, DxPopup, DxHeaderFilter, DxSearchPanel,
        DxLoadPanel,
        DxItem, DxEmptyItem,
        DxButton,
        DxToolbarItem
    },

    async created() {
        await Promise.all([
            this.FetchCustomers(),
            this.FetchWarehouses(),
            this.FetchSalesPersons(),
            this.FetchSalesTaxSchedules()
        ])
    },

    data() {
        return {
            customers: [],
            customercontacts: [],
            salespersons: [],
            salestaxschedules: [],
            selectedCustomer: null,
            isCustomerGridBoxOpened: false,
            shiptos: [],
            warehouses: [],
            loading: false,
            loadingText: '',

            saveButtonOptions: {
                icon: 'save',
                type: 'success',
                text: 'Save',
                onClick: (e) => this.save(e)
            },

            cancelButtonOptions: {
                icon: 'Cancel',
                type: 'default',
                text: 'Cancel',
                onClick: (e) => this.save(e)
            }
        }
    },

    methods: {
        states() {
            return states
        },

        async FetchCustomers() {
            this.loading = true
            this.loadingText = "Loading Customers..."

            API.get('/customers')
                    .then(response => {
                        this.customers = response.data.map(customer => new Customer(customer))
                    })
                    .catch(error => {
                        this.$toast.error(error);
                    })
                    .finally(() => {
                        this.loading = false
                        this.loadingText = ""
                    })
        },

        async FetchShipTo() {
            this.loading = true
            this.loadingText = "Loading Ship To Addresses..."

            API.get(`/shipto/${this.selectedCustomer}`)
                    .then(response => {
                        this.shiptos = response.data.map(shipto => new ShipTo(shipto))
                    })
                    .catch(error => {
                        this.$toast.error(error);
                    })
                    .finally(() => {
                        this.loading = false
                        this.loadingText = ""
                    })
        },

        async FetchWarehouses() {
            this.loading = true
            this.loadingText = "Loading Warehouses..."

            API.get('/warehouses')
                    .then(response => {
                        this.warehouses = response.data.map(warehouses => new Warehouse(warehouses))
                    })
                    .catch(error => {
                        this.$toast.error(error);
                    })
                    .finally(() => {
                        this.loading = false
                        this.loadingText = ""
                    })
        },

        async FetchCustomerContacts() {
            this.loading = true
            this.loadingText = "Loading Customer Contacts..."

            API.get(`/customercontacts/${this.selectedCustomer}`)
                    .then(response => {
                        this.customercontacts = response.data.map(contact => new CustomerContact(contact))
                    })
                    .catch(error => {
                        this.$toast.error(error);
                    })
                    .finally(() => {
                        this.loading = false
                        this.loadingText = ""
                    })
        },

        async FetchSalesPersons() {
            this.loading = true
            this.loadingText = "Loading Sales People..."

            API.get(`/salespersons`)
                    .then(response => {
                        this.salespersons = response.data.map(salesperson => new SalesPerson(salesperson))
                    })
                    .catch(error => {
                        this.$toast.error(error);
                    })
                    .finally(() => {
                        this.loading = false
                        this.loadingText = ""
                    })
        },

        async FetchSalesTaxSchedules() {
            this.loading = true
            this.loadingText = "Loading Tax Schedules..."

            API.get(`/salestaxschedules`)
                    .then(response => {
                        this.salestaxschedules = response.data.map(salestaxschedule => new SalesTaxSchedule(salestaxschedule))
                    })
                    .catch(error => {
                        this.$toast.error(error);
                    })
                    .finally(() => {
                        this.loading = false
                        this.loadingText = ""
                    })
        },

        async onCustomerSelectionChanged({ selectedRowsData}) {
            if(selectedRowsData) {
                this.selectedCustomer = selectedRowsData[0].CustomerNo
                this.isCustomerGridBoxOpened = false

                await Promise.all([
                    await this.FetchShipTo(),
                    await this.FetchCustomerContacts(),
                ])
            }
        },

        async onCustomerValueChanged(e) {
            if(!e.value) {
                this.selectedCustomer = null
                this.shiptos = []
                this.customercontacts = []
            }
        },

        customerDropDownBoxDisplayExpr(item) {
            return item && `${item.CustomerNo} - ${item.CustomerName}`
        },

        onSaved(e) {
            this.loading = true
            this.loadingText = "Updating Ship To Address..."

            if(e.changes.length && e.changes[0].data && e.changes[0].type === "update") {
                API.post('/shipto', e.changes[0].data)
                    .catch(error => {
                        this.$toast.error(error);
                    })
                    .finally(() => {
                        this.loading = false
                        this.loadingText = ""
                    })
            }
        },
    },

    computed: {
        ShipToDataGrid() {
            return this.$refs['ShipToDataGridRef'].instance
        }
    }
}
</script>

<style scoped>
.dx-datagrid-rowsview .dx-master-detail-row:not(.dx-datagrid-edit-form) .dx-master-detail-cell, .dx-datagrid-rowsview .dx-master-detail-row:not(.dx-datagrid-edit-form) > .dx-datagrid-group-space {
    background-color: #7d7d7d !important;
}
</style>
