export default class CustomerContact {
	constructor(options) {
		const defaults = {
			ARDivisionNo: null,
			CustomerNo: null,
			ContactCode: null,
			ContactName: null,
			MASUpdated: null,
		}
		
		this.ARDivisionNo = options.ARDivisionNo || defaults.ARDivisionNo
		this.CustomerNo = options.CustomerNo || defaults.CustomerNo
		this.ContactCode = options.ContactCode || defaults.ContactCode
		this.ContactName = options.ContactName || defaults.ContactName
		this.MASUpdated = options.MASUpdated || defaults.MASUpdated
	}
}
