export default class SalesTaxSchedule {
	constructor(options) {
		const defaults = {
			TaxSchedule: null,
			TaxScheduleDesc: null,
			PrintTaxCodeOnForms: null,
			MASUpdated: null,
		}
		
		this.TaxSchedule = options.TaxSchedule || defaults.TaxSchedule
		this.TaxScheduleDesc = options.TaxScheduleDesc || defaults.TaxScheduleDesc
		this.PrintTaxCodeOnForms = options.PrintTaxCodeOnForms || defaults.PrintTaxCodeOnForms
		this.MASUpdated = options.MASUpdated || defaults.MASUpdated
	}
}
