import CustomerContact from "@/models/CustomerContact";

export default class ShipTo {
	constructor(options) {
		const defaults = {
			ARDivisionNo: null,
			CustomerNo: null,
			ShipToCode: null,
			ShipToName: null,
			ShipToAddress1: null,
			ShipToAddress2: null,
			ShipToAddress3: null,
			ShipToCity: null,
			ShipToState: null,
			ShipToZipCode: null,
			ShipVia: null,
			EmailAddress: null,
			ContactCode: null,
			WarehouseCode: null,
			SalesTaxSchedule: null,
			SalespersonNo: null,
			UDF_LATITUDE: null,
			UDF_LONGITUDE: null,
			UDF_ACTIVE: false,
			UDF_AVALARANOTES: null,
		}
		
		this.ARDivisionNo = options.ARDivisionNo || defaults.ARDivisionNo
		this.CustomerNo = options.CustomerNo || defaults.CustomerNo
		this.ShipToCode = options.ShipToCode || defaults.ShipToCode
		this.ShipToName = options.ShipToName || defaults.ShipToName
		this.ShipToAddress1 = options.ShipToAddress1 || defaults.ShipToAddress1
		this.ShipToAddress2 = options.ShipToAddress2 || defaults.ShipToAddress2
		this.ShipToAddress3 = options.ShipToAddress3 || defaults.ShipToAddress3
		this.ShipToCity = options.ShipToCity || defaults.ShipToCity
		this.ShipToState = options.ShipToState || defaults.ShipToState
		this.ShipToZipCode = options.ShipToZipCode || defaults.ShipToZipCode
		this.ShipVia = options.ShipVia || defaults.ShipVia
		this.EmailAddress = options.EmailAddress || defaults.EmailAddress
		this.ContactCode = options.ContactCode || defaults.ContactCode
		this.WarehouseCode = options.WarehouseCode || defaults.WarehouseCode
		this.SalesTaxSchedule = options.SalesTaxSchedule || defaults.SalesTaxSchedule
		this.SalespersonNo = options.SalespersonNo || defaults.SalespersonNo
		this.UDF_LATITUDE = options.UDF_LATITUDE || defaults.UDF_LATITUDE
		this.UDF_LONGITUDE = options.UDF_LONGITUDE || defaults.UDF_LONGITUDE
		this.UDF_ACTIVE = options.UDF_ACTIVE || defaults.UDF_ACTIVE
		this.UDF_AVALARANOTES = options.UDF_AVALARANOTES || defaults.UDF_AVALARANOTES
	}
}
