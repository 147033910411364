export default class SalesPerson {
	constructor(options) {
		const defaults = {
			SalespersonDivisionNo: null,
			SalespersonNo: null,
			SalespersonName: null,
			Created: null,
			Updated: null,
		}
		
		this.SalespersonDivisionNo = options.SalespersonDivisionNo || defaults.SalespersonDivisionNo
		this.SalespersonNo = options.SalespersonNo || defaults.SalespersonNo
		this.SalespersonName = options.SalespersonName || defaults.SalespersonName
		this.Created = options.Created || defaults.Created
		this.Updated = options.Updated || defaults.Updated
	}
}
